import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Query } from 'src/graphql-types';

import Layout from '../layout';
import { serializers } from '../serializers';

export const query = graphql`
  query Query {
    allSanityPickupDelivery {
      edges {
        node {
          title
          _rawBlurb
        }
      }
    }
  }
`;

const Page = ({ data }: { data: Query }) => {
  return (
    <Layout title="Pickup & Delivery">
      <div className="w-full px-10 bg-white" style={{ maxWidth: 1200 }}>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10">
          {data.allSanityPickupDelivery.edges.map(({ node }) => {
            return (
              <div key={node.title}>
                <h2>{node.title}</h2>
                <BlockContent
                  blocks={node._rawBlurb}
                  serializers={serializers}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Page;
